<template>
  <div v-loading="isLoading" id="comment-management-detail">

    <el-row type="flex" justify="end" align="middle" class="status-row">
      <span class="el-form-item__label text-bold">{{ $t('STATUS') }}:</span>
      <el-select 
        v-model="detail.status"
        :class="getStatusClass(detail.status) + (isMobile ? ' w-100': '')"
        :disabled="fullAccess ? !canChangeStatus(detail.status) : true"
      >
        <el-option
          v-for="item in allSelectableStatusListFor(detail.status)"
          :key="item.value"
          :label="$t(item.value)"
          :value="item.value"
        />
      </el-select>
    </el-row>

    <el-form 
      ref="form" 
      :model="detail" 
      label-position="top" 
      label-width="120px"
      disabled
    >

      <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
        <el-col>
          <el-form-item :label="$t('TIME')">
            <el-input :value="parseDate(detail.createdDate)"/>
          </el-form-item>
        </el-col>

        <el-col :span="isMobile ? 24 : 8">
          <el-form-item :label="$t('ARTICLE_LINK')">
            <div class="el-input el-input--medium is-disabled">
              <div class="el-input__inner">
                <el-link 
                  type="primary"                  
                  @click="goToArticle(detail.article,true)"
                >
                  {{ $t("CLICK_TO_OPEN") }}
                </el-link>
                <i 
                  class="custom-icon mini pointer icon-copy"
                  @click="copyArticleLink(detail.article)"
                />
              </div>
            </div>
          </el-form-item>
        </el-col>

        <el-col>
          <el-form-item :label="$t('ARTICLE_NAME')">
            <el-input :value="detail.article.name | translate"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" justify="space-between" align="middle" :gutter="40">

        <el-col>
          <el-form-item :label="$t('COMMENT')">
            <el-input type="textarea" resize="none" :rows="7" :value="detail.text"/>
          </el-form-item>
        </el-col>

      </el-row>

    </el-form>

    <page-footer
      :isLoading="isLoading"
      :handleBackTo="exit"
      :handleSubmit="(fullAccess && detail.status != 'ARCHIVED') ? changeStatus : null"
      :handleArchive="(isAdmin && detail.status != 'ARCHIVED') ? handleArchive : null"
    />

  </div>
</template>


<script>
import { generalMixin } from '@/utils/general-mixin.js';
import PageFooter from "@/components/page-footer.vue"
import { getCommentById, archiveCommentById, changeStatus } from "@/api/comment"

export default {
  name: 'CommentManagementDetail',
  components: { PageFooter },
  mixins: [ generalMixin ],
  data() {
    return {
      isLoading: false,
      detail: {
        createdDate: null,
        article: {
          id: null,
          name: [],
        },
        text: null,
      }
    }
  },
  methods: {
    // REDIRECTION
    exit() { this.goTo('/bo/comment') },
    getDetail() {
      this.isLoading = true
      getCommentById(this.id)
      .then(res => {
        if (res.status == 'ok') {
          this.detail = res.result
        }
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    changeStatus() {
      this.isLoading = true
      changeStatus({
        id: this.id,
        status: this.detail.status
      })
      .then(res => {
        if (res.status == 'ok') {
          this.$notify({
            type: 'success',
            message: this.$t("UPDATE_SUCCESSFULLY"),
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION
          })
        }
        this.exit()
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    handleArchive() {
      this.isLoading = true
      archiveCommentById(this.id)
      .then(res => {
        if (res.status == 'ok') {
          this.$notify({
            type: 'success',
            message: this.$t("ARCHIVED_SUCCESSFULLY"),
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION
          })
        }
        this.exit()
      })
      .finally(() => {
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getDetail()
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/general.scss";
@import "@/assets/style/mixin/detail.scss";

#comment-management-detail {
  @include detail-language-options;
  
  background-color: $white;
  border-radius: 10px;
  padding-bottom: 2rem;
  padding-top: 2rem;
  .status-row {
    margin: auto $spacingLeftRight;
    .el-input__prefix, .el-input__suffix {
      top: 0;
    }
  }
  .el-form {
    margin: auto $spacingLeftRight;
  }
  .icon-copy {
    margin-left: 1rem
  }
  .el-link {
    margin-bottom: 10px
  }
}

.mobile #comment-management-detail {
  .icon-copy {
    float: right;
    margin-top: 0.5rem;
  }
}
</style>